import Component from 'vue-class-component'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'

// Base Page
import BasePage from '@/pages/Base'

// Services
import UserSettingsService from '@/services/UserSettings'
import CityServices from '@/services/City'
import CountryService from '@/services/Country'
import ProvinceServices from '@/services/Province'
import StatusService from '@/services/Status'
import moment from 'moment'

@Component({})
export default class UserSettings extends BasePage {
  constructor() {
    super()
  }
  //verified user
  isOpenVerified: boolean = false
  documentsID: any = {}
  documentsSelfie: any = {}
  idStatus = null
  itemStatus: object[] = []
  status: any = {}
  imageID = null
  imageNameId = null
  imageSelfie = null
  imageNameSelfie = null
  verificationStatus: string = 'unverified'

  userDialogData: any = {}
  userDialog: boolean = false

  canOrderItems: any = [
    {
      text: 'Trading',
      value: 'trading'
    },
    {
      text: 'Forwarding',
      value: 'forwarding'
    }
  ]

  //user details
  userID: string | number = null
  useAsEdit: boolean = false
  name: string = ''

  // User Activity
  lastLogin: any = ''
  lastSession: any = ''
  createdAt: any = ''

  // Tax Information
  businessList: any = ['business', 'individual', 'partnership']
  numberTypeList: any = [
    {
      text: 'Identity Card (ID) / KTP',
      value: false
    },
    {
      text: 'Taxpayer Identification / NPWP',
      value: true
    }
  ]
  taxTypeList: any = [
    {
      text: 'Non PKP / Pengusaha Kecil',
      value: false
    }, 
    {
      text: 'PKP / Pengusaha Kena Pajak',
      value: true
    }
  ]

  country_signup: string | number = null

  //is_partner is_agent
  radioButton: any = ''
  is_agent: number = 0
  is_partner: number = 0

  user_is_agent: boolean = false
  user_is_partner: boolean = false

  //membership
  dateDialogOpen: boolean = false
  dateStartAtDialogOpen: boolean = false
  membershipsList: any = []

  settings: any = []

  getCountriesLoading: boolean = false
  countries: any = [] 

  province: any = []
  getProvinceLoading: boolean = false

  city: any = []
  getCityLoading: boolean = false

  itemCountry: any = []
  getCountryLoading: boolean = false

  filter: any = {
    origin: [],
    from: {
      selected: null,
      keyword: null
    },
    label: 'Filter Membership',
    hint: 'Apply Membership',
    icon: 'mdi-filter-variant',
    loading: false
  }
  withdrawal_method: any = [
    {
      text: 'Bank Transfer',
      value: 'bank_transfer'
    }
  ]
  itemCountryDefault: any = []

  openModalVerification: boolean = false
  statusLoading: boolean = false

  viaPlaceholder: any = 'https://via.placeholder.com/150';

  getMarkingCodesName(codesParams: any) {
    const codesName: any = []
    if (codesParams !== []) {
      for (const key in codesParams) {
        const code_name = codesParams[key].attributes.code
        codesName.push(code_name)
      }
    }
    return codesName
  }

  getMembershipId(membershipParams: any) {
    const membersipId: any = []
    if (!isEmpty(membershipParams)) {
      for (const key in membershipParams) {
        const id = membershipParams[key].attributes.id
        membersipId.push(id)
      }
    }
    return membersipId
  }

  getExrasValue(extrasParams: any, keyName: string ) {
    var extrasTemp = {}
    if (!isEmpty(extrasParams)) {
      extrasTemp = {
        taxes_name: extrasParams.taxes_name ? extrasParams.taxes_name : '',
        id_number: extrasParams.taxes_id ? extrasParams.taxes_id : extrasParams.identity_card, // (pengecekan tax id / identity card)
        taxes_address: extrasParams.taxes_address ? extrasParams.taxes_address : '',
        type_of_bussines: extrasParams.type_of_bussines ? extrasParams.type_of_bussines : '',
        id_number_type:  extrasParams.taxes_id ? true : false,
        registered_vat: extrasParams.registered_vat,
        activated: extrasParams.activated ? extrasParams.activated : 0,
        first_name: extrasParams.first_name ? extrasParams.first_name : '',
        last_name: extrasParams.last_name ? extrasParams.last_name : '',
        withdraw_method: extrasParams.withdraw_method ? extrasParams.withdraw_method : '',
        withdraw_provider: extrasParams.withdraw_provider ? extrasParams.withdraw_provider : '',
        withdraw_account_name: extrasParams.withdraw_account_name ? extrasParams.withdraw_account_name : '',
        withdraw_account_number: extrasParams.withdraw_account_number ? extrasParams.withdraw_account_number : '',
        withdraw_swift_code: extrasParams.withdraw_swift_code ? extrasParams.withdraw_swift_code : ''
      }
    } else {
      extrasTemp = {
        taxes_name: '',
        id_number: '',
        taxes_address: '',
        type_of_bussines: '',
        id_number_type: '',
        registered_vat: null,
        activated: 0,
        first_name: '',
        last_name: '',
        withdraw_method: '',
        withdraw_provider: '',
        withdraw_account_name: '',
        withdraw_account_number: '',
        withdraw_swift_code: ''
      }
    }
    return extrasTemp[keyName]
  }

  getAddressInformation(addressParams: any) {
    var addressAttributes: any = {}
    if (isEmpty(addressParams)) {
      return ''
    } else {
      for (const key in addressParams) {
        addressAttributes = addressParams[key].attributes
      }
    }
    return addressAttributes
  }

  getVerificationIdentity (dataParams) {
    if (!isEmpty(dataParams.data.relationships.documentVerification[0])) {
      const id_doc = dataParams.data.relationships.documentVerification[0].id
      const documentID = dataParams.included.documentVerification[id_doc].attributes.url
      this.documentsID = documentID
    } else {
      this.documentsID = 'https://via.placeholder.com/150?text=No Image Available'
    }

    if (!isEmpty(dataParams.data.relationships.documentVerification[1])) {
      const id_doc = dataParams.data.relationships.documentVerification[1].id
      const documentID = dataParams.included.documentVerification[id_doc].attributes.url
      this.documentsSelfie = documentID
    } else {
      this.documentsSelfie = 'https://via.placeholder.com/150?text=No Image Available'
    }

    if (!isEmpty(dataParams.data.relationships.verificationStatus)) {
      for (const status of dataParams.data.relationships.verificationStatus) {
        const id_status = dataParams.included.verificationStatus[status.id].attributes.id
        const statusName = dataParams.included.verificationStatus[status.id].attributes.name
        this.verificationStatus = statusName
        this.idStatus = id_status
      }
    } else {
      this.idStatus = 50
    }
  }

  checkMember(memberships) {
    const nonmember = [
      'Seller', 'Super Admin', 'Staff', 'QC', 'Accountant', 'Sales', 'Owner', 'Subscriber', 'Administrator', 'Agent'
    ]
    var check = false
    for (let non of nonmember) {
      if (non === memberships.name || non === memberships.text) {
        check = true
        break
      }
    }
    return check
  }

  async mounted() {
    this.showLoading({ text: 'Getting User Details...' })
    this.userID = this.$route.params.id
    await this.getOneUsers(this.$route.params.id)
    this.getCountries()
    this.getProvince()
    this.getCity()
    this.getCountry()
    this.getCountriesDefault()
    this.getMemberships()
    this.getStatus()
    this.closeLoading()
  }

  async reffresh() {
    this.userID = this.$route.params.id
    await this.getOneUsers(this.$route.params.id)
    this.getCountries()
    this.getProvince()
    this.getCity()
    this.getCountry()
    this.getCountriesDefault()
    this.getMemberships()
    this.getStatus()
  }

  async getOneUsers(id) {
    try {
      const opts: any = {
        params: {
          include: 'items,invoices,platforms,parents,childs,codes,verificationStatus,documentVerification,addresses,memberships'
        }
      }
      const responseOneUser = await UserSettingsService.getOneUser(id, opts)
      const dataUser = responseOneUser.data.data.attributes
      const dataUserIncluded = responseOneUser.data.included
      const dataRelationOneUser = responseOneUser.data.data.relationships.settings
      var settings = {
        default_shipping_to: '',
        currency: ''
      }
      for (const settingsUser of dataRelationOneUser) {
        const dataIncludedOneUser = responseOneUser.data.included.settings[settingsUser.id].attributes
        if (dataIncludedOneUser.key === 'default_shipping_to') {
          settings.default_shipping_to = dataIncludedOneUser.value
        } else if (dataIncludedOneUser.key === 'currency') {
          settings.currency = dataIncludedOneUser.value
        }
      }
      this.settings = settings
      if (dataUser.membership.start_at != null) {
        this.userDialogData = {
          name: dataUser.name,
          email: dataUser.email,
          referral_url: dataUser.referral_url,
          referral_code: dataUser.referral_code,
          referral_name: '-',
          shipping_list: dataUser.shipping_list,
          default_shipping_to: parseInt(this.settings.default_shipping_to),
          currency: this.settings.currency,
          phone: dataUser.phone,
          password: '',
          password_confirmation: '',
          membership: this.getMembershipId(dataUserIncluded.memberships),
          marking_codes: this.getMarkingCodesName(dataUserIncluded.codes),
          country_selected: dataUser.country_catalogs,
          country_signup: dataUser.country_signup,
          country_catalog_active: dataUser.country_catalog_active,
          taxes_address: this.getExrasValue(dataUser.extras, 'taxes_address'),
          id_number:  this.getExrasValue(dataUser.extras, 'id_number'),
          taxes_name: this.getExrasValue(dataUser.extras, 'taxes_name'),
          type_of_bussines: this.getExrasValue(dataUser.extras, 'type_of_bussines'),
          id_number_type: this.getExrasValue(dataUser.extras, 'id_number_type'),
          registered_vat: this.getExrasValue(dataUser.extras, 'registered_vat'),
          addresses_id: this.getAddressInformation(dataUserIncluded.addresses).id,
          street_address: this.getAddressInformation(dataUserIncluded.addresses).address_line,
          province: this.getAddressInformation(dataUserIncluded.addresses).province_id,
          city: this.getAddressInformation(dataUserIncluded.addresses).city_id,
          subdistrict: this.getAddressInformation(dataUserIncluded.addresses).subdistrict,
          postal: this.getAddressInformation(dataUserIncluded.addresses).zip_code,
          saved_country_signup: dataUser.country_signup ? true : false,
          can_order: dataUser.can_order,
          member_expired_date: dataUser.membership.expired_at ? this.toDatabaseDate(dataUser.membership.expired_at) : null,
          member_expired_dateFormatted: dataUser.membership.expired_at ? this.toFullDate(dataUser.membership.expired_at) : 'Member Expired Date',
          member_expired_tempDate: null,
          member_start_at_date: this.toDatabaseDate(dataUser.membership.start_at),
          member_start_at_dateFormatted: this.toFullDate(dataUser.membership.start_at),
          member_start_at_tempDate: null,
          withdraw_method: this.getExrasValue(dataUser.extras, 'withdraw_method'),
          bank_name: this.getExrasValue(dataUser.extras, 'withdraw_provider'),
          bank_account_number: this.getExrasValue(dataUser.extras, 'withdraw_account_number'),
          bank_account_name: this.getExrasValue(dataUser.extras, 'withdraw_account_name'),
          bank_swift_code: this.getExrasValue(dataUser.extras, 'withdraw_swift_code')
        }
      } else {
        this.userDialogData = {
          name: dataUser.name,
          email: dataUser.email,
          referral_url: dataUser.referral_url,
          referral_code: dataUser.referral_code,
          referral_name: '-',
          shipping_list: dataUser.shipping_list,
          default_shipping_to: parseInt(this.settings.default_shipping_to),
          currency: this.settings.currency,
          // shipping_list_names: dataUser.shipping_list_names,
          phone: dataUser.phone,
          password: '',
          password_confirmation: '',
          membership: this.getMembershipId(dataUserIncluded.memberships),
          marking_codes: this.getMarkingCodesName(dataUserIncluded.codes),
          country_selected: dataUser.country_catalogs,
          country_signup: dataUser.country_signup,
          country_catalog_active: dataUser.country_catalog_active,
          taxes_address: this.getExrasValue(dataUser.extras, 'taxes_address'),
          id_number:  this.getExrasValue(dataUser.extras, 'id_number'),
          taxes_name: this.getExrasValue(dataUser.extras, 'taxes_name'),
          type_of_bussines: this.getExrasValue(dataUser.extras, 'type_of_bussines'),
          id_number_type: this.getExrasValue(dataUser.extras, 'id_number_type'),
          registered_vat: this.getExrasValue(dataUser.extras, 'registered_vat'),
          addresses_id: this.getAddressInformation(dataUserIncluded.addresses).id,
          street_address: this.getAddressInformation(dataUserIncluded.addresses).address_line,
          province: this.getAddressInformation(dataUserIncluded.addresses).province_id,
          city: this.getAddressInformation(dataUserIncluded.addresses).city_id,
          subdistrict: this.getAddressInformation(dataUserIncluded.addresses).subdistrict,
          postal: this.getAddressInformation(dataUserIncluded.addresses).zip_code,
          saved_country_signup: dataUser.country_signup ? true : false,
          can_order: dataUser.can_order,
          member_expired_date: dataUser.membership.expired_at ? this.toDatabaseDate(dataUser.membership.expired_at) : null,
          member_expired_dateFormatted: dataUser.membership.expired_at ? this.toFullDate(dataUser.membership.expired_at) : 'Member Expired Date',
          member_expired_tempDate: null,
          member_start_at_date: null,
          member_start_at_dateFormatted: 'Member Start Date',
          member_start_at_tempDate: null,
          withdraw_method: this.getExrasValue(dataUser.extras, 'withdraw_method'),
          bank_name: this.getExrasValue(dataUser.extras, 'withdraw_provider'),
          bank_account_number: this.getExrasValue(dataUser.extras, 'withdraw_account_number'),
          bank_account_name: this.getExrasValue(dataUser.extras, 'withdraw_account_name'),
          bank_swift_code: this.getExrasValue(dataUser.extras, 'withdraw_swift_code')
        }
      }
      // verification status
      this.getVerificationIdentity(responseOneUser.data)
      // agent or partner
      if (dataUser.is_agent === 1) {
        this.radioButton = 'is_agent'
        this.user_is_agent = true
      } else if (dataUser.is_agent === 0) {
        this.radioButton = 'is_agent'
        this.user_is_agent = false
      }  
      if (dataUser.is_partner === 1) {
        this.radioButton = 'is_partner'
        this.user_is_partner = true
      }else if (dataUser.is_partner === 0) {
        this.radioButton = 'is_partner'
        this.user_is_partner = false
      }
      this.useAsEdit = true
      this.userDialog = true
      if (dataUser.company) {
        this.userDialogData.company_name = dataUser.company.name
        this.userDialogData.company_logo = null
        this.userDialogData.company_country = dataUser.company.country_id
        this.userDialogData.company_id = dataUser.company.id
        this.userDialogData.company_logo_preview = dataUser.company.logo
      } else {
        this.userDialogData.company_name = null
        this.userDialogData.company_logo = null
        this.userDialogData.company_country = null
        this.userDialogData.company_id = null
        this.userDialogData.company_logo_preview = this.viaPlaceholder
      }

      // get referral name
      if (!isEmpty(responseOneUser.data.data.relationships.parents)) {
        const id_referral = responseOneUser.data.data.relationships.parents[0].id
        this.userDialogData.referral_name = dataUserIncluded.parents[id_referral].attributes.name
      }

      // set User Activity
      this.lastLogin = dataUser.last_login ? moment(dataUser.last_login).fromNow() : '-'
      this.lastSession = dataUser.last_session ? this.toDateTime(dataUser.last_session) : '-'
      this.createdAt = dataUser.created_at ? this.toDateTime(dataUser.created_at) : '-'

    } catch (error) {
      this.catchHandler(error)
    }
  }

  async getCountries() {
    this.getCountriesLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }
      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      this.countries = []
      for (const country of responseData) {
        this.countries.push(country.attributes)
      }
      this.countries = sortBy(this.countries, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCountriesLoading = false
    }
  }

  async getProvince() {
    this.getProvinceLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10, // Calling all country. Reducing constant filter request to the server
          'filter[name][like]': '',
          'filter[country_id][is]': this.userDialogData.country_signup
        }
      }
      const response = await ProvinceServices.getProvinces(opts)
      const responseData = response.data.data
      this.province = []
      for (const province of responseData) {
        this.province.push(province.attributes)
      }
      this.province = sortBy(this.province, 'name')

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getProvinceLoading = false
    }
  }

  async getCity() {
    this.getCityLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          'filter[name][like]': '',
          'filter[province_id][is]': this.userDialogData.province
        }
      }
      const response = await CityServices.getCities(opts)
      const responseData = response.data.data
      this.city = []
      for (const city of responseData) {
        this.city.push(city.attributes)
      }
      this.city = sortBy(this.city, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCityLoading = false
    }
  }

  async getCountry() {
    this.getCountryLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }
      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      this.itemCountry = []
      for (const country of responseData) {
        this.itemCountry.push(country.attributes)
      }
      this.itemCountry = sortBy(this.itemCountry, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCountryLoading = false
    }
  }
  async getCountriesDefault() {
    this.getCountryLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }
      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      this.itemCountryDefault = []
      for (const country of responseData) {
        this.itemCountryDefault.push(country.attributes)
      }
      this.itemCountryDefault = sortBy(this.itemCountryDefault, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCountryLoading = false
    }
  }

  //membership
  onSelectDateInput(event) {
    this.userDialogData.member_expired_tempDate = event
  }

  onSelectDateCancel() {
    this.dateDialogOpen = false
  }

  onSelectDateDone() {
    if (this.userDialogData.member_expired_tempDate) {
      this.userDialogData.member_expired_date = this.userDialogData.member_expired_tempDate
      this.userDialogData.member_expired_dateFormatted = this.toFullDate(
        this.userDialogData.member_expired_tempDate
      )
    }
    this.dateDialogOpen = false
  }

  onSelectDateStartAtCancel() {
    this.dateStartAtDialogOpen = false
  }

  onSelectDateStratAtInput(event) {
    this.userDialogData.member_start_at_tempDate = event
  }

  onSelectDateStartAtDone() {
    if (this.userDialogData.member_start_at_tempDate) {
      this.userDialogData.member_start_at_date = this.userDialogData.member_start_at_tempDate
      this.userDialogData.member_start_at_dateFormatted = this.toFullDate(
        this.userDialogData.member_start_at_tempDate
      )
    }
    this.dateStartAtDialogOpen = false
  }

  async getMemberships() {
    const opts: any = {
      params: {
        'page[limit]': 30
      }
    }
    try {
      const responseMemberships = await UserSettingsService.getMemberships(opts)
      const dataMemberships = responseMemberships.data.data
      for (const memberships of dataMemberships) {
        const membership: any = {
          name: memberships.attributes.name,
          id: memberships.attributes.id
        }
        if (this.checkMember(membership) === false) {
          this.membershipsList.push(membership)
          this.filter.origin.push(membership)
        }
        const membershipsFilter: any = {
          text: memberships.attributes.name,
          value: memberships.attributes.name
        }
        if (this.checkMember(membershipsFilter) === false) {
          this.filter.origin.push(membershipsFilter)
        }
      }
    } catch (error) {
      this.catchHandler(error)
    }
  }

  //status
  async getStatus() {
    this.statusLoading = true
    const opts: any = {
      params: {
        'filter[status_type][is]': 6
      }
    }
    try {
      const response = await StatusService.getStatusUser(opts)
      this.itemStatus = []
      for (let status of response.data) {
        const statuses: any = {
          id: status.attributes.id,
          name: status.attributes.name
        }
        this.itemStatus.push(statuses)
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.statusLoading = false
    }
  }

  //verified dialog
  async openVerified() {
    this.status = this.verificationStatus
    this.isOpenVerified = true
  }

  imageid(e) {
    const file = e.target.files[0]
    if ((file.size / 1024) < 500) {
      this.imageID = file
      this.imageNameId = file.name
    } else {
      this.imageID = null
      this.imageNameId = null
        ; (this.$refs as any).resetData.value = ''
      this.showSnackbar({
        text: 'File size exceeds 500 KB',
        color: 'red',
        timeout: 2000
      })
    }
  }

  imageselfie(e) {
    const file = e.target.files[0]
    if ((file.size / 1024) < 500) {
      this.imageSelfie = file
      this.imageNameSelfie = file.name
    } else {
      this.imageSelfie = null
      this.imageNameSelfie = null
        ; (this.$refs as any).resetData.value = ''
      this.showSnackbar({
        text: 'File size exceeds 500 KB',
        color: 'red',
        timeout: 2000
      })
    }
  }

  async saveVerified() {
    try {
      this.showLoading({ text: 'Saving...' })
      if (this.status == 'unverified' && this.imageID != null) {
        let data = new FormData()
        data.append('document', this.imageID)
        data.append('name', this.imageNameId)
        data.append('additional_document', this.imageSelfie)
        data.append('additional_document_name', this.imageNameSelfie)
        await UserSettingsService.documents(this.userID, data)
      } else {
        var data = {
          relationships: [{
            type: 'user_verification',
            attributes: {
              id: this.idStatus
            }
          }]
        }
        await UserSettingsService.update(this.userID, data)
      }
      this.isOpenVerified = false
      this.showSnackbar({
        text: 'Identity Updated Successfully!',
        color: 'green',
        timeout: 2500
      }) 
      this.reffresh()
    } catch (error) {
      this.catchHandler(error)
      this.isOpenVerified = false
    } finally {
      this.closeLoading()
    }
  }

  validatePassword() {
    if (this.userDialogData.password_confirmation == this.userDialogData.password) {
      return true
    } else {
      return false
    }
  }

  isRequiredLogo() {
    var check = false
    if (this.userDialogData.type_of_bussines === 'business') {
      if (this.userDialogData.company_logo_preview !== this.viaPlaceholder) {
        if (this.userDialogData.company_logo) {
          check = true
        }
      }
    } else {
      check = true
    }
    return check
  }

  async updateCompany() {
    try {
      let configfile = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + window.localStorage.getItem('access_token'),
          Identifier: 'manage'
        }
      }
      let data = new FormData()
      data.append('logo', this.userDialogData.company_logo)
      data.append('name', this.userDialogData.company_name)
      data.append('country_id', this.userDialogData.company_country)

      await UserSettingsService.updateCompany(this.userDialogData.company_id, data, configfile)
    } catch (error) {
      this.catchHandler(error)
    } finally {
      // this.closeLoading()
    }
  }

  changeLogo(e) {
    const file = e.target.files[0]
    this.userDialogData.company_logo = file
    this.userDialogData.company_logo_preview = file
  }

  async addCompany(iduser) {
    try {
      let configfile = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + window.localStorage.getItem('access_token'),
          Identifier: 'manage'
        }
      }
      let data = new FormData()
      data.append('logo', this.userDialogData.company_logo)
      data.append('name', this.userDialogData.company_name)
      data.append('country_id', this.userDialogData.company_country)
      data.append('members[]', iduser)

      await UserSettingsService.addCompany(data, configfile)
    } catch (error) {
      this.catchHandler(error)
    } finally {
      // this.closeLoading()
    }
  }
  async updateUser() {
    await setTimeout(async () => {
      try {
        this.showLoading({ text: 'Saving...' })
        const validationResponse = await this.$validator.validateAll('userDialogScope')
        if (validationResponse) {
          if (this.validatePassword()) {
            if (this.isRequiredLogo()) {
              this.userDialog = false
              var codes = []
              this.showLoading({ text: 'Saving...' })
              if (!isEmpty(this.userDialogData.marking_codes)) {
                for (const code of this.userDialogData.marking_codes) {
                  const marking_codes = {
                    code: code
                  }
                  codes.push(marking_codes)
                }
              }
              var payload_is_agent = 0
              var payload_is_partner = 0
              if (this.user_is_agent === true) {
                payload_is_agent = 1
              }
              if (this.user_is_partner === true) {
                payload_is_partner = 1
              }
              var dialogData = {
                is_agent: payload_is_agent,
                is_partner: payload_is_partner,
                referral_url: this.userDialogData.referral_url,
                referral_code: this.userDialogData.referral_code,
                shipping_list: this.userDialogData.shipping_list,
                name: this.userDialogData.name,
                email: this.userDialogData.email,
                phone: this.userDialogData.phone,
                password: this.userDialogData.password,
                password_confirmation: this.userDialogData.password_confirmation,
                membership_ids: this.userDialogData.membership,
                marking_codes: codes,
                country_catalogs: this.userDialogData.country_selected,
                country_signup: this.userDialogData.country_signup,
                country_catalog_active: this.userDialogData.country_catalog_active,
                can_order: this.userDialogData.can_order,
                expired_at: this.userDialogData.member_expired_date,
                start_at: this.userDialogData.member_start_at_date,
                extras: {
                  taxes_address: this.userDialogData.taxes_address,
                  taxes_id: this.userDialogData.type_of_bussines === 'business' ? this.userDialogData.id_number : null, 
                  identity_card: null, 
                  taxes_name: this.userDialogData.taxes_name,
                  type_of_bussines: this.userDialogData.type_of_bussines,
                  registered_vat:this.userDialogData.type_of_bussines === 'business' || this.userDialogData.id_number_type ? this.userDialogData.registered_vat : null,
                  withdraw_method: this.userDialogData.withdraw_method,
                  withdraw_provider: this.userDialogData.bank_name,
                  withdraw_account_name: this.userDialogData.bank_account_name,
                  withdraw_account_number: this.userDialogData.bank_account_number,
                  withdraw_swift_code: this.userDialogData.bank_swift_code
                },
                settings: [
                  {
                    key: 'currency',
                    value: this.userDialogData.currency
                  },
                  {
                    key: 'default_shipping_to',
                    value: this.userDialogData.default_shipping_to
                  }
                ],
                addresses: [
                  {
                    id: this.userDialogData.addresses_id,
                    address_line: this.userDialogData.street_address,
                    province_id: this.userDialogData.province,
                    city_id: this.userDialogData.city,
                    subdistrict: this.userDialogData.subdistrict,
                    zipcode: this.userDialogData.postal,
                    country_id: this.userDialogData.country_signup
                  }
                ]
              }

              // pengecekan id_number masuk ke taxes_id atau ke Identity_card
              if(this.userDialogData.type_of_bussines !== 'business') {
                if(this.userDialogData.id_number_type){
                  dialogData.extras.taxes_id = this.userDialogData.id_number
                  dialogData.extras.identity_card = null
                } else {
                  dialogData.extras.taxes_id = null
                  dialogData.extras.identity_card = this.userDialogData.id_number
                }
              }
              
              if (isEmpty(dialogData.password) || isEmpty(dialogData.password_confirmation)) {
                delete dialogData.password
                delete dialogData.password_confirmation
              }
              if (!dialogData.country_signup) {
                delete dialogData.country_signup
              }
              if (dialogData.country_catalogs === null) {
                dialogData.country_catalogs = '[]'
              }

              // console.log(JSON.stringify(dialogData), 'Final Payload before save')

              await UserSettingsService.update(this.userID, dialogData)
              if (this.userDialogData.type_of_bussines === 'business') {
                if (this.userDialogData.company_id) {
                  await this.updateCompany()
                } else {
                  await this.addCompany(this.userID)
                }
              }
              this.closeLoading()
              this.showSnackbar({
                text: 'User Updated Successfully!',
                color: 'green',
                timeout: 2500
              })
              this.reffresh()
              this.useAsEdit = false
            } else {
              this.showSnackbar({
                text: 'Company logo is required',
                color: 'red',
                timeout: 3500
              })
            }
          } else {
            this.showSnackbar({
              text: "Password doesn't match",
              color: 'red',
              timeout: 3500
            })
          }
        } else {
          this.showSnackbar({
            text: 'Please check all filed(s) requirements',
            color: 'red',
            timeout: 3500
          })
        }
      } catch (error) {
        if (error.response.data.code == '23000') {
          this.showSnackbar({
            text: 'Marking Codes that you inserted already exist!',
            color: 'red',
            timeout: 3500
          })
        } else {
          this.showSnackbar({
            text: error.response.data.message,
            color: 'red',
            timeout: 3500
          })
        }
      } finally {
        this.closeLoading()
      }
    }, 100)
  }
}
